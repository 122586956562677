.root {
    width: 100%;
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 1fr;
    grid-template-rows: fit-content(100px);
}
.basket {
    display: grid;
    grid-template-columns: 1fr 50px;
    padding: 2px 10px;
}
.basketName {
    font-weight: bold;
}
.qtyHeader {
    text-align: right;
    font-size: 0.8em;
}
.name {
    font-size: 0.8em;
    display: flex;
    align-items: center;
    line-height: 12px;
}
.id {
    font-size: 0.8em;
    display: flex;
    align-items: left;
    line-height:12px;
}
.qty {
    font-size: 0.7em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}