.root {
    height: 100%;
    width: 100%;
    position: relative;
}
.selector {
    position: absolute;
    top: 30px;
    left: 10px;
    width: 300px;
    z-index: 10;
}
.chart {
    height: 100%;
    width: 100%;
    position: absolute;
    top:0;
    left: 0;
    border: 1px solid #d0d0d0;
}