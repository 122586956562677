.root {
	display:flex;
    align-items: flex-end;
    flex-direction: row;
}
.section {
	display:flex;
    align-items: flex-end;
}
.divider {
	margin-left:40px;
	margin-right:40px;
}
.label {
}
.button {
	height:30px;
	border:none;
	border-radius:4px;
	margin:3px;
    transition-duration: 0.4s;
    padding-left:15px;
    padding-right:15px;
}
.button:hover {
    background: #b1b1b1;

}
.input {
	height:32px;
	border-radius:4px;
	border:none;
	padding-left:5px;
}