.root {
  height: 97vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:#666;
}
.header {
  align-items: flex-start;
}
.title {

}
.selectors {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.selector {
  width: 500px;
  max-width: 50%;
  margin: 5px;
}
.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
}
.chart {
  flex: 1 1 auto;
}
.itemsBasket {
  width: 27em;
  border: 1px solid #d0d0d0;
  max-width: 50%;
  background: #e2e2e2;
}
.side {
  flex: 1 1 auto;
  max-width: 600px;
}

:global(.ag-react-container) {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
:global(.ag-theme-balham .ag-cell){
  line-height: 40px !important;
}